import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthsessionService } from '../auth/authsession.service';
import { SESSION_BACKOFFICE } from '@shared/data/data';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements CanActivate {

  constructor(private router: Router, private authService:AuthsessionService) { }
  canActivate() {
    // If the user is not logged in we'll send them back to the home page
    if (!this.authService.setNameSession(SESSION_BACKOFFICE).isLogged()) {
        console.log('No estás logueado');
        this.router.navigate(['/auth']);
        return false;
    }
    return true;
}

}

