import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'wnbo-password-change-success',
  templateUrl: './password-change-success.component.html',
  styleUrls: ['./password-change-success.component.scss']
})
export class PasswordChangeSuccessComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PasswordChangeSuccessComponent>, private router:Router) { }

  ngOnInit() {
  }

  jumpLink() {
    this.router.navigate(['/auth']);
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }


}
