export const MENU_LIST =[
    {name: "request", label: "Solicitudes", active:false, menuActive: false, url: "/", icon: "assets/images/ico-request.svg", child:[
        {permission: "create_request", url: "/", active: false},
        {permission: "list_request", url: "/", active: false},
        {permission: "delete_request", url: "/", active: false},
        {permission: "update_request", url: "/", active: false},
    ]},
    {name: "request", label: "Mesa de control", active:false, menuActive: false, url: "/", icon: "assets/images/ico-control-desk.svg", child:[
        {permission: "create_request", url: "/validator", active: false},
        {permission: "list_request", url: "/validator", active: false},
        {permission: "delete_request", url: "/validator", active: false},
        {permission: "update_request", url: "/validator", active: false},
    ]},
    {name: "request", label: "Análisis crediticio", active:false, menuActive: false, url: "/", icon: "assets/images/icon-analista.svg", child:[
        {permission: "create_request", url: "/analyst", active: false},
        {permission: "list_request", url: "/analyst", active: false},
        {permission: "delete_request", url: "/analyst", active: false},
        {permission: "update_request", url: "/analyst", active: false},
    ]},
    {name: "request", label: "Investigador", active:false, menuActive: false, url: "/", icon: "assets/images/icon-investigador.svg", child:[
        {permission: "create_request", url: "/investigator", active: false},
        {permission: "list_request", url: "/investigator", active: false},
        {permission: "delete_request", url: "/investigator", active: false},
        {permission: "update_request", url: "/investigator", active: false},
    ]},
    {name: "request", label: "Referencias", active:false, menuActive: false, url: "/", icon: "assets/images/icon-referencias-menu.svg", child:[
        {permission: "create_request", url: "/references", active: false},
        {permission: "list_request", url: "/references", active: false},
        {permission: "delete_request", url: "/references", active: false},
        {permission: "update_request", url: "/references", active: false},
    ]},
    {name: "user", label: "Usuarios", active:false, menuActive: false, url: "/admin/users", icon: "assets/images/ico-users.svg", child:[
        {permission: "create_user", url: "/admin/users", active: false},
        {permission: "delete_user", url: "/admin/users", active: false},
        {permission: "update_user", url: "/admin/users", active: false},
        {permission: "list_user", url: "/admin/users", active: false},
        {permission: "list_role", url: "/admin/roles", active: false},
        {permission: "create_role", url: "/admin/roles", active: false},
        {permission: "delete_role", url: "/admin/roles", active: false},
        {permission: "update_role", url: "/admin/roles", active: false},
    ]},    

]
