import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BoardsService } from '@shared/services/http/boards.services';
import { Router } from '@angular/router';
import { ModalService } from '@shared/services/modal/modal.service';

@Component({
  selector: 'wnbo-request-references',
  templateUrl: './request-references.component.html',
  styleUrls: ['./request-references.component.scss']
})
export class RequestReferencesComponent implements OnInit {
  public panelOpenState;
  public item;
  public percentage = 37;
  public cardStatus = { label: '', class: '' };
  constructor(
    public dialogRef:MatDialogRef<RequestReferencesComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private boardSvc:BoardsService,
    private router:Router
  ) { }

  ngOnInit() {
    this.cardStatus = this.boardSvc.getCardStatus;
    this.item = this.data;
    this.getUserInfo();
  }
  async getUserInfo() {
    try {
      const resp = await this.boardSvc.getDataProspect(this.item.email);
      this.item.personalInfo = resp.data;
      console.log('request reference', this.item);
    } catch (error) {
      console.log(error);
    }
  }

  closeModal(option?) {
    this.dialogRef.close(option);
  }

  async assign(){
    try {
      const resp = await this.boardSvc.requestAssignToMCV(this.item.card_id);
      console.log(resp);
      this.closeModal();
      this.router.navigate(['references/reference-validation/' + this.item.card_id +'/'+this.item.request_id]);
    } catch (error) {
      this.boardSvc.setProspectEmail = '';
      this.closeModal(0);
      console.log(error);
    }
  }

}
