import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BoardsService } from '@shared/services/http/boards.services';
import { Router } from '@angular/router';

@Component({
  selector: 'wnbo-request-investigator',
  templateUrl: './request-investigator.component.html',
  styleUrls: ['./request-investigator.component.scss']
})
export class RequestInvestigatorComponent implements OnInit {
  panelOpenState;
  item;
  public percentage = 37;
  public cardStatus = { label: '', class: '' };


  constructor(
    public dialogRef:MatDialogRef<RequestInvestigatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private boardSvc:BoardsService,
    private router:Router
  ) { }

  ngOnInit() {
    this.cardStatus = this.boardSvc.getCardStatus;
    this.item = this.data;
    this.getUserInfo();
  }

  closeModal(option?) {
    this.dialogRef.close(option);
  }

  async getUserInfo() {
    try {
      const resp = await this.boardSvc.getDataProspect(this.item.email);
      this.item.personalInfo = resp.data;
      console.log(this.item);
    } catch (error) {
      console.log(error);
    }
  }

  async assign(){
    try {
      const resp = await this.boardSvc.requestAssignToInvestigator(this.item.card_id);
      console.log(resp);
      this.closeModal();
      this.router.navigate(['investigator/visit-validation/' + this.item.card_id +'/'+this.item.request_id]);
    } catch (error) {
      this.boardSvc.setProspectEmail = '';
      this.closeModal(0);
      console.log(error);
    }
  }

}
