import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AssistedProcessService } from '@shared/services/http/assisted-process.service';
import { ModalService } from '@shared/services/modal/modal.service';

@Component({
  selector: 'wnbo-prospect-data',
  templateUrl: './prospect-data.component.html',
  styleUrls: ['./prospect-data.component.scss']
})
export class ProspectDataComponent implements OnInit {
  stepsData;
  ProspectDataComponent;
  value;
  email;

  /* constructor(public dialogRef:MatDialogRef<ProspectDataComponent>, private stepsFormsSvc:AssistedProcessService, private dialogSvc:ModalService) { } */
  constructor(public dialogRef:MatDialogRef<ProspectDataComponent>, private stepsFormsSvc:AssistedProcessService) { }

  ngOnInit() {
    /* let modal = this.dialogSvc.loader(); */
    this.stepsFormsSvc.getStepsFieldsCont(this.email).then(data => {
      if(data.data){
        this.stepsData = data.data;
      }
      /* modal.close(); */
    }, error => {
      console.log(error);
      /* modal.close(); */
    })
  }

  closeModal() {
    this.dialogRef.close();
  }

}
