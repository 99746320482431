import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BoardsService {
  private mcvProgress: any;
  private cardStatus = {
    label: '',
    class: ''
  };
  private prospectEmail = '';
  private prospectCardData: any;
  private updateContactPhone = ''; // opciones prospect/work/family/known
  private checkStepToBackToBoard = new Subject<number>();

  constructor(private httpSvc:HttpService) { }

  get getMCVProgress() {
    return this.mcvProgress;
  }

  set setprospectCardData(cardData) {
    this.prospectCardData = cardData;
  }

  get getprospectCardData() {
    return this.prospectCardData;
  }

  set setPhoneToUpdate(person) {
    this.updateContactPhone = person;
  }

  get getContactToUpdateContact() {
    return this.updateContactPhone;
  }

  set setMCVProgress(progress) {
    this.mcvProgress = progress;
  }

  get getProspectEmail() {
    return this.prospectEmail != '' ? this.prospectEmail : undefined;
  }

  // con esto preguntamos desde el componente padre de validación
  get getCheckStepToBackToBoard() {
    return this.checkStepToBackToBoard;
  }

  public setCheckStepToBackToBoard(step) {
    this.checkStepToBackToBoard.next(step);
  }
  
  set setProspectEmail(email) {
    this.prospectEmail = email;
  }

  get getCardStatus() {
    return this.cardStatus;
  }

  set setCardStatus(status) {
    this.cardStatus = status;
  }

  requestCalculatePayment(body) {
    let path = "backoffice-credit-analyst/payment";
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().post(path, body, header).toPromise();
  }

  requestAppoveCredit(body) {
    let path = "backoffice-credit-analyst/validation";
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().post(path, body, header).toPromise();
  }

  getValidationProgress(idCard) {
    let path = 'backoffice-documentary-validator/validation?card_id=' + idCard;
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().get(path,header).toPromise();
  }

  getBoard(name){
      let path = "backoffice-board?board_id="+name
      let header = this.httpSvc.loadToken();
      return this.httpSvc.setUrlBase().get(path, header).toPromise();
  }

  getTable(path){
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().get(path, header).toPromise();
  }

  getDataProspect(email) {
    let path = "backoffice-user";
    let header = this.httpSvc.loadToken();
    let params = {
      "email_user": email
    };
    return this.httpSvc.setUrlBase().post(path, params, header).toPromise();
  }

  requestAssignToMCV(cardId) {
    let path = "backoffice-multichannel-sales/requests";
    let params = {
      "is_assigned": true,
      "card_id": cardId
    };
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, params,header).toPromise();
  }

  requestReleaseMCV(cardId) {
    let path = "backoffice-multichannel-sales/requests";
    let params = {
      "is_assigned": false,
      "card_id": cardId
    };
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, params,header).toPromise();
  }

  requestMCVCalls() {}

  requestMCVSubCat() {
    let path = "backoffice-multichannel-sales/status";
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().get(path, header).toPromise();

  }
  requestMCVProspectProgress(requestId) {
    let path = 'backoffice-multichannel-sales/applicant?request_id='+requestId;
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().get(path, header).toPromise();
  }

  sendMCVProstectStatus(body) {
    let path = 'backoffice-multichannel-sales/call/new';
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().post(path, body,header).toPromise();
  }

  requestMCVLastCall(body) {
    let path = 'backoffice-multichannel-sales/call';
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().post(path, body,header).toPromise();
  }

  requestMCVProspectHistorical(requestId) {
    let path = 'backoffice-multichannel-sales/calls/applicant?request_id='+requestId;
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().get(path, header).toPromise();
  }

  requestMCVRecordCalls(body) {
    let path = 'backoffice-multichannel-sales/calls';
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().post(path, body,header).toPromise();
  }

  requestAssignToInvestigator(cardId) {
    let path = "backoffice-investigator/requests";
    let params = {
      "is_assigned": true,
      "card_id": cardId
    };
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, params,header).toPromise();
  }

  requestReleaseFromInvestigator(cardId) {
    let path = "backoffice-investigator/requests";
    let params = {
      "is_assigned": false,
      "card_id": cardId
    };
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, params,header).toPromise();
  }

  requestInvProgress(requestId) {
    const path = 'backoffice-investigator?request_id=' + requestId;
    const header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().get(path,header).toPromise();
  }

  requestSaveInvForm(body) {
    const path = 'backoffice-investigator';
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, body,header).toPromise();

  }

  requestAssignToValidator(cardId) {
    let path = "backoffice-documentary-validator/requests";
    let params = {
      "is_assigned": true,
      "card_id": cardId
    };
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, params,header).toPromise();
  }

  requestReleaseCard(cardId) {
    let path = "backoffice-documentary-validator/requests";
    let params = {
      "is_assigned": false,
      "card_id": cardId
    };
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, params,header).toPromise();
  }

  requestAssignToAnalyst(cardId) {
    let path = "backoffice-credit-analyst/requests";
    let params = {
      "is_assigned": true,
      "card_id": cardId
    };
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, params,header).toPromise();
  }
  requestReleaseFromAnalyst(cardId) {
    let path = "backoffice-credit-analyst/requests";
    let params = {
      "is_assigned": false,
      "card_id": cardId
    };
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, params,header).toPromise();
  }

  requestSaveDocumentForm(body) {
    let path = "backoffice-documentary-validator/validations";
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().post(path,body, header).toPromise();
  }


  assignedRequest(card_id){
    let params = {
        "is_assigned": true,
        "card_id": card_id
    }
    let path = "backoffice-documentary-validator/requests"
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, params,header).toPromise();
  }
}