import { Component, OnInit, Input, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ModalService } from '@shared/services/modal/modal.service';
import { SimulatorService } from '@shared/services/http/simulator.service';
import { CarListService } from '@shared/services/http/car-list.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wnbo-simulation-result',
  templateUrl: './simulation-result.component.html',
  styleUrls: ['./simulation-result.component.scss']
})
export class SimulationResultComponent implements OnInit, OnChanges, OnDestroy {

  public gps: number;
  public openingComissionPercentage: number;
  public openingComission: number;
  public monthlyPayment: any;
  private subscriptor: Subscription;
  @Input() creditAmount: number = 0;
  @Input() creditDownPayment: number = 0;
  @Input() carCost: number = 0;
  @Output() saved = new EventEmitter();

  constructor(
    private modalService: ModalService,
    private simulatorService: SimulatorService,
    private carListService: CarListService
  ) {
    this.subscriptor = this.simulatorService.getSubjectMonthlyPayment().subscribe(
      value => this.monthlyPayment = value
    );
  }

  ngOnInit() {
    this.getProduct()
  }

  ngOnChanges() {
    this.openingComission = this.creditAmount * (this.openingComissionPercentage / 100)
    
  }

  public getProduct() {
    this.simulatorService.getFactoryCredit().then(
      success => {
        let data = success.data
        this.openingComissionPercentage = parseFloat(data.comission);
        this.gps = parseFloat(data.fees[2].amount)
      }, error => console.error(error)
    )
  }

  public viewAmortizationTable() {
    if(this.monthlyPayment){
      let data = this.getSimulatorValues();
      let pass = this.modalService.setHeigth("80%").setWidth("90%").setParams(data).amortizationTable()
      pass.afterClosed().toPromise()
    }
  }

  public shareTable() {
    if(this.monthlyPayment) {
      let data = this.getSimulatorValues();
      this.modalService.setHeigth("400px").setWidth("500px").setParams(data).shareAmortizationTable()
    }
  }

  public downloadAmortizationTable(){
    if(this.monthlyPayment) {
      let data = this.getSimulatorValues();
      this.carListService.downloadAmortizationTable(data).then(response => {
        const downloadLink = document.createElement("a");
        const fileName = "tabla_de_amortizacion.pdf";
        downloadLink.href = response.data;
        downloadLink.download = fileName;
        downloadLink.click();
      })
    }
  }

  public getSimulatorValues() {
    return {
      credit_amount: this.creditAmount,
      credit_down_payment: this.creditDownPayment,
      credit_monthly_payment: this.monthlyPayment.payment_amount,
      credit_period_payment: this.monthlyPayment.payment,
      credit_car_cost: this.carCost
    }
  }

  public onNext() {
    this.saved.emit(true)
  }

  ngOnDestroy(){
    this.subscriptor.unsubscribe();
  }

}
