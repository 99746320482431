import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GetUrlFileService } from '@shared/services/s3/get-url-file.service';
import { AuthsessionService } from '@shared/services/auth/authsession.service';
import { UserService } from '@shared/services/http/user.service';
import { AssistedProcessService } from '@shared/services/http/assisted-process.service';
import { ModalService } from '@shared/services/modal/modal.service';

@Component({
  selector: 'wn-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent {
  @Input() elements: number = 1;
  @Input() vertical: boolean = false;
  @Input() label: string = 'Frente*';
  @Input() type: string;
  @Input() email:string;
  @Output() status = new EventEmitter();
  @Input() dataImgs1;
  @Input() dataImgs2;
  @Input() dataImgs3;

  loader1Status:boolean=false;
  loader2Status:boolean=false;
  loader3Status:boolean=false;

  public isErrorSize = false;


  documentType = {

  }
  constructor(
    private fileSvc: GetUrlFileService,
    private authSessionService: AuthsessionService,
    private asistedSvc: AssistedProcessService,
    private modalSvc: ModalService
  ) {}

  upload(items, numberImgs?: number) {
    if (!this.isValidateSizeImage(items)) { return; }
    let reader = new FileReader();
    if (!numberImgs) {
      numberImgs = 1;
    }
    let _this = this;
    reader.onload = () => {
      if (numberImgs == 1) {
        _this.dataImgs1 = reader.result
        this.loader1Status = true;
      }
      if (numberImgs == 2) {
        _this.dataImgs2 = reader.result
        this.loader2Status = true;
      }
      if (numberImgs == 3) {
        _this.dataImgs3 = reader.result
        this.loader3Status = true;
      }
      _this.sendSvc(reader.result, items.target.files[0].name, numberImgs)
    }
    reader.readAsDataURL(items.target.files[0])
  }

  //SERVICIO QUE SE MODIFICO, REVISAR
  sendSvc(base64: string | ArrayBuffer, nameFile: string, numberImgs: number) {
    let nameField: string;
    let typeDocument: string;
    let field: string;

    switch (this.type) {
      case "proof":
        switch (numberImgs) {
          case 1:
            nameField = "last_salary_slips";
            break;
          case 2:
            nameField = "antepenultimate_salary_slips";
            break;
          case 3:
            nameField = "previus_salary_slips";
            break;
        }
        field = nameField;
        break;
      case "ine":
        typeDocument = this.type
        if (numberImgs == 1) {
          nameField = "id_front"
        } else if (numberImgs == 2) {
          nameField = "id_back"
        }
        break;
      case "professional_id":
        typeDocument = this.type
        if (numberImgs == 1) {
          nameField = "id_front"
        }
        break;
      case "passport":
        typeDocument = this.type
        if (numberImgs == 1) {
          nameField = "id_front"
        }
        break;
      case "address":
        if (numberImgs == 1) {
          nameField = "address_proof"
        }
        break;
    }
    if (!this.email || this.email == "") {
      let data = this.authSessionService.setNameSession('UserProcesoAsistido').getAllData();
      this.email = data.email
    }
    this.fileSvc.uploadFile(base64,nameFile,nameField, typeDocument, this.email).then(success => {
      this.validImagesLoad();
    }, error => {
      if (this.dataImgs1) {
        this.loader1Status = false
      }
      if (this.dataImgs2) {
        this.loader2Status = false
      }
      if (this.dataImgs2) {
        this.loader3Status = false
      }
      if (error.message == 'Timeout has occurred') {
        this.modalSvc.setPanelClass('error-upload')
          .labelButtonAccept('Continuar')
          .setTextTitle('¡Ha ocurrido un error!')
          .setTextMessage('Lo sentimos, no se ha podido cargar el documento, intenta nuevamente')
          .error();
      }
    })
  }

  validImagesLoad()
  {
    if(this.dataImgs1){
      this.loader1Status = false
    }
    if(this.dataImgs2){
      this.loader2Status = false
    }
    if(this.dataImgs2){
      this.loader3Status = false
    }
    switch(this.elements){
      case 1:
        if(this.dataImgs1){
          this.loader1Status = false
          this.status.emit(true)
        }
        break;
      case 2:
        if(this.dataImgs1 && this.dataImgs2){
          this.status.emit(true)
        }
        break;
      case 3:
        if(this.dataImgs1 && this.dataImgs2 && this.dataImgs3){

          this.status.emit(true)
        }
        break;
      default:
        this.status.emit(false)
    }
  }

  public isValidateSizeImage(items: any) {
    this.isErrorSize = items.target.files[0].size > 10485760;
    return !this.isErrorSize
  }
}
