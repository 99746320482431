import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wnbo-monthly-payments',
  templateUrl: './monthly-payments.component.html',
  styleUrls: ['./monthly-payments.component.scss']
})
export class MonthlyPaymentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
