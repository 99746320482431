import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'wnbo-exit-doc-validation-without-save',
  templateUrl: './exit-doc-validation-without-save.component.html',
  styleUrls: ['./exit-doc-validation-without-save.component.scss']
})
export class ExitDocValidationWithoutSaveComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ExitDocValidationWithoutSaveComponent>, private router: Router) { }

  ngOnInit() {
  }

  closeModal(opt?) {
    this.dialogRef.close(opt);
  }

  cancelOperation() {
    this.dialogRef.close();
    this.router.navigate(['/dummy']);
  }

}
