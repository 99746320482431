import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BoardsService } from '@shared/services/http/boards.services';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'wnbo-credit-analyst-application',
  templateUrl: './credit-analyst-application.component.html',
  styleUrls: ['./credit-analyst-application.component.scss']
})
export class CreditAnalystApplicationComponent implements OnInit {
  public panelOpenState;
  public labelStatus;
  private statusClass: string;
  public percentage = 25;
  public item;

  constructor(
    public dialogRef: MatDialogRef<CreditAnalystApplicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private boardSvc: BoardsService,
    private router: Router) { }

  ngOnInit() {
    console.log(this.data);
    this.getUserInfo();
  }

  closeModal(option?) {
    this.dialogRef.close(option);
  }

  flagStatus() {
    let status = this.data.status;
    let ngClass = "status-2";
    this.labelStatus = "Baja";
    this.data.timeLife = this.timeLife();

    if (this.data.timeLife < 12) {
      ngClass = "status-2";
      this.labelStatus = "Baja";
    } else if (this.data.timeLife > 12 && this.data.timeLife < 24) {
      ngClass = "status-1"
      this.labelStatus = "Media";
    } else {
      ngClass = "status-0"
      this.labelStatus = "Alta";
    }
    this.statusClass = ngClass;
    return ngClass;
  }

  timeLife() {
    let startTime = moment(this.data.created_at)
    let end = moment()
    let duration = moment.duration(end.diff(startTime));
    let hours = duration.asHours();
    return Math.floor(hours);
  }

  async assign(){
    try {
      const resp = await this.boardSvc.requestAssignToAnalyst(this.data.card_id);
      console.log(resp);
      //email del prospecto guardada en el servicio
      this.boardSvc.setProspectEmail = this.item.email;
      this.boardSvc.setCardStatus = {label: this.labelStatus, class: this.statusClass};
      this.closeModal();
      this.router.navigate(['analyst/payment-capacity/'+this.data.card_id+'/'+this.data.request_id]);
    } catch (error) {
      this.boardSvc.setProspectEmail = '';
      this.closeModal(0);
      console.log(error);
    }
  }


  async getUserInfo() {
    try {
      const resp = await this.boardSvc.getDataProspect(this.data.email);
      this.item = resp.data;
      console.log(this.item);
    } catch (error) {
      console.log(error);
    }
  }



}
