import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'wnbo-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {
  @Input() title:string ="";
  @Input() message:string = "";
  @Input() close:string ="";
  @Input() accept:string = "";
  @Input() question="";
  constructor(public dialogRef: MatDialogRef<WarningComponent>) { }

  ngOnInit() {
  }

  continue(){
    this.dialogRef.close(true);    
  }

  closeModal() {
    this.dialogRef.close();
  }


}
