import { Component, OnInit, Inject, Input } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  @Input() title:string ="";
  @Input() message:string = "";
  @Input() close:string ="";
  @Input() accept:string = "";
  constructor( public dialogRef: MatDialogRef<ErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {

  }



  aceptar()
  {
      this.dialogRef.close(true);
  }

  cerrar(): void {
    let data = {
      uno:"dos",
      treces:"cuatro"
    }
      this.dialogRef.close(data);
    }

}
