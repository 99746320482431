import { Injectable } from '@angular/core';
import { UserService } from '../http/user.service';
import { HttpService } from '../http/http.service';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetUrlFileService {
  private timeLoad = 60000;

  constructor(private userSvc:UserService, private httpSvc:HttpService) { }

  //servicio para eliminar imagen subida por investigador
  deleteInvImage(body) {
    const path = 'backoffice-investigator/document';
    const header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().delete(path,body,header).toPromise();
  }

  //servicio para subir imagenes de investigador 
  uploadInvImage(image, userData, documentType) {
    console.log('documentType', documentType)
    const path = 'backoffice-investigator/document';
    const header = this.httpSvc.loadToken();
    let fileBase = image.file.split(',');
    let typeDoc = fileBase[0];
    var file: string;
    if(typeDoc.includes('pdf')){
      file = image.file.replace(/^data:application\/[a-z]+;base64,/, "");
    } else {
      file = image.file.replace(/^data:image\/[a-z]+;base64,/, "");
    }
    // const file = image.file.replace(/^data:image\/[a-z]+;base64,/, "");
    const body: any= {
      email_user: userData.email,
      request_id: userData.requestId,
      name_document: image.name,
      document: file,
      type_investigation: documentType.type_investigation,
      subtype_investigation: documentType.subtype_investigation
    };
    if (documentType.subtype_document) {
      body.subtype_document =  documentType.subtype_document;
    }
    return (new Promise((resolve, reject,) => {
      this.httpSvc.setUrlBase().put(path, body, header).pipe(timeout(this.timeLoad), catchError(e => {
        reject(e);
        return of(null);
      })).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error)
      });
    }));



  }

  //SERVICIO QUE SE MODIFICO, REVISAR
  uploadFile(file, nameFiled, typeDocument, subTypeDocument, email) {

    let path = "backoffice-document";
    let base64: string;
    let fileBase = file.split(',')
    let typeDoc = fileBase[0]

    if(typeDoc.includes('pdf')){
      base64 = file.replace(/^data:application\/[a-z]+;base64,/, "");
    } else {
      base64 = file.replace(/^data:image\/[a-z]+;base64,/, "");
    }
    let header = this.httpSvc.loadToken();
    let params = {
      "email_user": email,
      "name_document": nameFiled,
      "document": base64,
      "type_document": typeDocument
    }
    if (typeDocument == 'id_front' || typeDocument == 'id_back') {
      params['subtype_document'] = subTypeDocument;
    }
    // return this.httpSvc.setUrlBase().put(path, params, header).toPromise()
    return (new Promise((resolve, reject,) => {
      this.httpSvc.setUrlBase().put(path, params, header).pipe(timeout(this.timeLoad), catchError(e => {
        reject(e);
        return of(null);
      })).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error)
      });
    }));
  }

  uploadUserFile(params) {

    let path = "backoffice-document";
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().post(path, params, header).toPromise()
  }
}
