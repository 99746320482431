import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'wnbo-credit-bureau-authorization',
  templateUrl: './credit-bureau-authorization.component.html',
  styleUrls: ['./credit-bureau-authorization.component.scss']
})
export class CreditBureauAuthorizationComponent implements OnInit {
  @Input() modalTitle:string = "";
  authorizationApproved;

  constructor(public dialogRef:MatDialogRef<CreditBureauAuthorizationComponent>) { }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
