import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SimulatorService {

  private subjectMonthlyPayment = new Subject();

  constructor(private httpSvc: HttpService) { }

  getFactoryCredit() {
    let path = "simulator/product";
    return this.httpSvc.setUrlBase(environment.landingDomain).get(path).toPromise();
  }

  getSimulationCredit(items: Simulator) {
    let path = "simulator";
    return this.httpSvc.setUrlBase(environment.landingDomain).post(path, items).toPromise();
  }

  public getSubjectMonthlyPayment() {
    return this.subjectMonthlyPayment;
  }

  public setSubjectMonthlyPayment(value: any) {
    return this.subjectMonthlyPayment.next(value);
  }

}
