export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MONEY_REGEX = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
export const SIMULATOR_YEAR = "model_years";
export const SIMULATOR_BRAND = "make_name";
export const SIMULATOR_NAME = "model_name";
export const SIMULATOR_VERSION = "version";
export const STEP_ORIGINACION = {
    "simulador": 0,
    "información personal": 1,
    "domicilio actual":2
}

export const SESSION_BACKOFFICE = "sessionbo";
export const SESSION_EDIT_ROL = "edit_rol";
export const SESSION_EDIT_USER = "edit_user";
export const ERROR_ROLE_USE = "Can't delete role, assign other role to users";

export const COLUMNS_TABLE = {
    request: '/requests',
    validate: '/validations',
    approved: '/approved'
};