import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MENU_LIST } from '@shared/data/menu';
import { AuthsessionService } from '@shared/services/auth/authsession.service';
import { SESSION_BACKOFFICE } from '@shared/data/data';

@Component({
  selector: 'wnbo-backoffice-sidenav',
  templateUrl: './backoffice-sidenav.component.html',
  styleUrls: ['./backoffice-sidenav.component.scss']
})
export class BackofficeSidenavComponent implements OnInit {

  @Input() iconStatus = false;
  menuList: Array<any> = [];

  constructor(
    private router: Router,
    private sessionSvc: AuthsessionService
  ) {
    this.menuList = MENU_LIST;
    this.validateNavigate()
  }

  ngOnInit() {

  }

  toGo(menu) {
    this.inactiveMenu();
    menu.menuActive = true;
    this.router.navigate([menu.url]);
  }

  inactiveMenu() {
    for (let index = 0; index < this.menuList.length; index++) {
      this.menuList[index].menuActive = false;
    }
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  validateNavigate() {
    this.menuList = MENU_LIST;
    const permision = this.sessionSvc.setNameSession(SESSION_BACKOFFICE).getKeySession('front');
    console.log(permision)
    for (let index = 0; index < this.menuList.length; index++) {
      for (let idx = 0; idx < this.menuList[index].child.length; idx++) {
        if (permision.menu.admin.includes(this.menuList[index].child[idx].permission)) {
          this.menuList[index].active = true;
          this.menuList[index].url = this.menuList[index].child[idx].url;
        }
      }
    }
    const filtermenu = this.menuList.filter(item => item.active);
    if (filtermenu.length > 0) {
      this.toGo(filtermenu[0]);
    }
  }

}
