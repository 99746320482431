import { Component, Input, Output, EventEmitter, forwardRef, OnInit, OnChanges, DoCheck } from '@angular/core';
import { FormControl, FormBuilder, Validators, NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

interface DropDown{
  key;
  value;

}
@Component({
  selector: 'wnbo-select-filter',
  templateUrl: './select-filter.component.html',
  providers:[
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectFilterComponent),
    multi: true
  }]
})
export class SelectFilterComponent implements OnInit, OnChanges {
  @Input() store: Array<string> = [];
  @Input() placeHolder: string = '';
  @Input() selected: string;
  @Output() selectChange = new EventEmitter();

  public data: Array<string> = [];
  public selectForm = new FormGroup({
    select: new FormControl('', Validators.required)
  });

  ngOnInit() {
    this.data = this.store;
  }

  ngOnChanges() {
    this.data = this.store
    if (this.selected) {
      this.selectForm.patchValue({select: this.selected})
    }
  }

  search(query: string) {
    this.data = this.store.filter(el => el.toLowerCase().indexOf(query.toLowerCase()) > -1)
  }

  selectChangeOption(event: any) {
    this.selectChange.emit(event.value);
  }
}
