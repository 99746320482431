import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ModalService } from '@shared/services/modal/modal.service';
import { COLUMNS_TABLE } from '@shared/data/data';
import { Router } from '@angular/router';
import { BoardsService } from '@shared/services/http/boards.services';

@Component({
  selector: 'wnbo-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() item;
  @Input() columnTable;
  labelStatus:string;
  private statusClass: string;
  constructor(
    private modalSvc:ModalService,
    private router: Router,
    private boardSvc:BoardsService
    ) { }

  ngOnInit() {
    this.timeLife();
  }

  flagStatus(){
    let status = this.item.status;
    let ngClass = "status-2";
    this.labelStatus = "Baja";
    this.item.timeLife = this.timeLife();
    this.item.timeHours = this.timeHours();

    if(this.item.date_schedule && this.item.hour_schedule) {
      var currentDate = moment();
      var shceduleDate: any = moment(this.item.date_schedule).format('l');
      var stringDate = shceduleDate + ' ' + this.item.hour_schedule;
      shceduleDate = moment(stringDate,'l h:mm A');
      var milliS = (moment.duration(shceduleDate.diff(currentDate)).asMinutes());
      var minutes = Math.floor(milliS)
      if (minutes < 15) {
        ngClass = "status-0"
        this.labelStatus = "Alta";
      } else {
        ngClass = "status-2";
        this.labelStatus = "Baja";
      }
      return ngClass;

    } else {
      if(this.item.timeHours < 12)
    {
      ngClass = "status-2";
      this.labelStatus = "Baja";
    }else if(this.item.timeHours > 12 && this.item.timeHours < 24)
    {
      ngClass = "status-1"
      this.labelStatus = "Media";
    }else{
      ngClass = "status-0"
      this.labelStatus = "Alta";
    }
    this.statusClass = ngClass;
    return ngClass;
    }
  }

  timeLife(){
    let startTime = moment(this.item.created_at)
    let end = moment()
    let duration = moment.duration(end.diff(startTime));
    let hours = duration.asHours();
    let minutes = Math.floor((hours%1)*60);
    let timeString = String(Math.floor(hours)).padStart(2,'0')+":"+String(minutes).padStart(2,'0')+":00"
    return timeString;
  }

  timeHours()
  {
    let startTime = moment(this.item.created_at)
    let end = moment()
    let duration = moment.duration(end.diff(startTime));
    let hours = duration.asHours();
    return Math.floor(hours);
  }

  eventClick() {
    this.boardSvc.setProspectEmail = this.item.email;
    this.boardSvc.setCardStatus = {label: this.labelStatus, class: this.statusClass};
    // this.router.navigate(['/analyst/resume'+'/'+this.item.card_id+'/'+this.item.request_id]);

    if(this.columnTable.loader.is_modal) {
        this.openActionModal(this.columnTable.loader.modal)
    } else {
      if(this.columnTable.loader.path) {
        this.goToPath(this.columnTable.loader.path)
      }
    }
  }

  openActionModal(modalName) {
    let modal = this.modalSvc.setParams(this.item).openModalCard(modalName);
    modal.afterClosed().subscribe( resp => {
      if (resp != undefined) {
        this.openModalAlreayAsign();
      }
    })
  }
  goToPath(path) {
    if(this.item.status != "inactive") {
      this.router.navigate([path+'/'+this.item.card_id+'/'+this.item.request_id]);
    }
  }

  openModalAlreayAsign() {
    this.modalSvc.alreadyAsignedToAnotherValidator();
  }

}





