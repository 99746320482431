import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AssistedProcessService {

  constructor(private httpService: HttpService) { }

  getStepsFields() {
    let path = "backoffice-user/steps";
    let header = this.httpService.loadToken();
    return this.httpService.setUrlBase().post(path, {}, header).toPromise();
  }

  getStepsFieldsCont(email: string) {
    let path = 'backoffice-user/steps';
    let body = {email_user: email};
    let header = this.httpService.loadToken();
    return this.httpService.setUrlBase().post(path, body, header).toPromise();
  }

  getProspectsList() {
    let path = "backoffice-user/list";
    let header = this.httpService.loadToken();
    return this.httpService.setUrlBase().get(path, header).toPromise();
  }

  saveStepData(formData: any) {
    let path = "backoffice-user";
    let header = this.httpService.loadToken();
    let params = formData;
    return this.httpService.setUrlBase().put(path, params, header).toPromise();
  }

  getDataProspect(email) {
    let path = "backoffice-user";
    let header = this.httpService.loadToken();
    let params = {
      "email_user": email
    };
    return this.httpService.setUrlBase().post(path, params, header).toPromise();
  }


}
