import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SuccessComponent } from './success/success.component'
import { ErrorComponent } from './error/error.component'
import { ConfirmComponent } from './confirm/confirm.component';
import { LoaderComponent } from './loader/loader.component';
import { PasswordChangeSuccessComponent } from '@shared/components/password-change-success/password-change-success.component';
import { AmortizationTableComponent } from '@shared/components/amortization-table/amortization-table.component';
import { ShareAmortizationTableComponent } from '@shared/components/share-amortization-table/share-amortization-table.component';
import { CreditBureauAuthorizationComponent } from '@shared/components/credit-bureau-authorization/credit-bureau-authorization.component';
import { AuthorizationProcessComponent } from '@shared/components/authorization-process/authorization-process.component';
import { ProspectDataComponent } from '@shared/components/prospect-data/prospect-data.component';
import { WarningDeletePermissionComponent } from '@shared/components/warning-delete-permission/warning-delete-permission.component';
import { WarningDeleteUserComponent } from '@shared/components/warning-delete-user/warning-delete-user.component';
import { ErroRoleDeleteComponent } from './erro-role-delete/erro-role-delete.component';
import { DocumentValidatorApplicationComponent } from '@shared/components/document-validator-application/document-validator-application.component';
import { ExitDocValidationWithoutSaveComponent } from '@shared/components/exit-doc-validation-without-save/exit-doc-validation-without-save.component';
import { ExitDocValidationWithSaveComponent } from '@shared/components/exit-doc-validation-with-save/exit-doc-validation-with-save.component';
import { DocumentValidatorApprovedDocumentationComponent } from '@shared/components/document-validator-approved-documentation/document-validator-approved-documentation.component';
import { CreditAnalystApplicationComponent } from '@shared/components/credit-analyst-application/credit-analyst-application.component';
import { CardAlreadyAssignedValidatorComponent } from '@shared/components/card-already-assigned-validator/card-already-assigned-validator.component';
import { DocumentImageComponent } from '@shared/components/document-image/document-image.component';
import { ConfirmValidationComponent } from '@shared/components/confirm-validation/confirm-validation.component';
import { ReleaseCreditRequestComponent } from '@shared/components/release-credit-request/release-credit-request.component';
import { RequestInvestigatorComponent } from '@shared/components/request-investigator/request-investigator.component';
import { ExitRequestComponent } from '@shared/components/exit-request/exit-request.component';
import { ReleaseRequestComponent } from '@shared/components/release-request/release-request.component';
import { RequestReferencesComponent } from '@shared/components/request-references/request-references.component';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { FilePreviewOverlayComponent } from '@shared/components/file-preview-overlay/file-preview-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  configModal = {
    minWidth: '300px',
    minHeight: '250px',
    disableClose: true,
    panelClass: ''
  }
  params;
  close: string = 'Cerrar';
  accept: string = 'Aceptar';
  title: string = '';
  message: string = '';
  loaderState: boolean = false;

  constructor(
    private dlg: MatDialog,
    private overlay: Overlay
    ) { }

  setWidth(params) {
    this.configModal['width'] = params
    return this;
  }

  setPanelClass(params) {
    this.configModal['panelClass'] = params
    return this;
  }

  setHeigth(params) {
    this.configModal['height'] = params
    return this;
  }

  setParams(params) {
    this.configModal["data"] = params
    return this;
  }

  setTextTitle(text: string) {
    this.title = text;
    return this;
  }

  setTextMessage(text: string) {
    this.message = text;
    return this;
  }

  labelButtonClose(label: string) {
    this.close = label;
    return this;
  }

  labelButtonAccept(label: string) {
    this.accept = label;
    return this;
  }

  success() {
    let modal = this.dlg.open(SuccessComponent, this.configModal);
    modal.componentInstance.accept = this.accept
    modal.componentInstance.title = 'Operación exitosa'
    modal.componentInstance.message = 'Su operación se realizo correctamente'
    return modal
  }

  error() {
    let modal = this.dlg.open(ErrorComponent, this.configModal);
    if (!this.message) {
      this.message = 'Algo salio mal, vuelve a intentarlo'

    }
    if (!this.title) {
      this.title = 'Error'
    }
    modal.componentInstance.close = this.close
    modal.componentInstance.accept = this.accept
    modal.componentInstance.title = this.title  // this.title
    modal.componentInstance.message = this.message
    return modal
  }

  confirm() {
    let modal = this.dlg.open(ConfirmComponent, this.configModal);
    modal.componentInstance.close = this.close
    modal.componentInstance.accept = this.accept
    modal.componentInstance.title = 'Confirmar'
    modal.componentInstance.message = this.message
    return modal
  }

  loader() {
    if (!this.loaderState) {
      this.loaderState = true;
      let configModal = {
        minWidth: '150px',
        minHeight: '150px',
        disableClose: true
      }
      let modal = this.dlg.open(LoaderComponent, configModal)
      modal.afterClosed().subscribe(success => {
        this.loaderState = false;
      });
      return modal;
    }
  }

  /* Modal de contraseña actualizada satisfactoriamente */
  passwordChangeSuccess() {
    let modal = this.dlg.open(PasswordChangeSuccessComponent, this.configModal);

    return modal
  }

  amortizationTable() {
    let modal = this.dlg.open(AmortizationTableComponent, this.configModal);
    return modal;
  }

  shareAmortizationTable() {
    let modal = this.dlg.open(ShareAmortizationTableComponent, this.configModal);
    modal.componentInstance.modalTitle = 'Envía tu cotización por correo'
  }

  bureauAuthorizationRequest() {
    let modal = this.dlg.open(CreditBureauAuthorizationComponent, this.configModal);
    modal.componentInstance.modalTitle = 'Autorización Buró de Crédito'
  }

  requestAuthorizationProcess() {
    let modal = this.dlg.open(AuthorizationProcessComponent, this.configModal);
    modal.componentInstance.modalTitle = 'Autorización de Crédito'
    return modal;
  }

  prospectFullInfo() {
    let modal = this.dlg.open(ProspectDataComponent, this.configModal);
  }

  deletingPermission() {
    let modal = this.dlg.open(WarningDeletePermissionComponent, this.configModal);
    return modal;
  }

  deletingUser() {
    let modal = this.dlg.open(WarningDeleteUserComponent, this.configModal);
    return modal;
  }

  errorDeleteRol() {
    let modal = this.dlg.open(ErroRoleDeleteComponent, this.configModal)
  }
  /*Modal para salir de una solicitud sin haber guardado*/
  exitWithoutSaveRequest() {
    return this.dlg.open(ExitRequestComponent, this.configModal);
  }

  /*Modal para liberar una solicitud y regresarla a la columna previa */
  releaseRequest() {
    return this.dlg.open(ReleaseRequestComponent, this.configModal);
  }


  /* Modal desplegado desde "Validador documental" > "Solicitudes" */
  documentValidatorApplication() {
    let modal = this.dlg.open(DocumentValidatorApplicationComponent, this.configModal);
    return modal;
  }

   /* modal para advertir del abandono del proceso de validación documental al haber guardado */
   exitDocumentValidationWithSaving() {
    var configs = {
      maxWidth: '500px',
      disableClose: false,
      panelClass: 'exit-validator-modal'
    }
    let modal = this.dlg.open(ExitDocValidationWithSaveComponent, configs);
    return modal;
  }

   /* modal para advertir del abandono del proceso de validación documental sin haber guardado se regresara a solicitudes */
   exitDocumentValidationWithoutSaving() {
    var configs = {
      maxWidth: '500px',
      disableClose: false,
      panelClass: 'exit-validator-modal'
    }
    let modal = this.dlg.open(ExitDocValidationWithoutSaveComponent, configs);
    return modal;
  }

   /* modal para advertir del abandono del proceso de validación del crédito */
   releaseCreditMoidal() {
    var configs = {
      maxWidth: '500px',
      disableClose: false,
      panelClass: 'exit-validator-modal'
    }
    let modal = this.dlg.open(ReleaseCreditRequestComponent, configs);
    return modal;
  }

  /*modal para ver los archivos de validación*/
  documentImageValidation() {
    var configs = {
      minWidth: '300px',
      minHeight: '250px',
      disableClose: false,
      panelClass: 'document-validator',
      data: this.configModal["data"]
    }
    let modal = this.dlg.open(DocumentImageComponent, configs);
  }

  //modal confirmación validación documento
  confirmValidation() {
    return this.dlg.open(ConfirmValidationComponent, this.configModal);
  }
  /* modal para advertir que una solicitud ya fue asignada a un validador */
  alreadyAsignedToAnotherValidator() {
    let modal = this.dlg.open(CardAlreadyAssignedValidatorComponent, this.configModal);
  }
  /* Modal desplegado desde "Validador documental" > "Documentación aprobada" */
  documentValidatorApprovedDocumentation() {
    return this.dlg.open(DocumentValidatorApprovedDocumentationComponent, this.configModal);
  }

  /* Modal desplegado desde "Analista crediticio" > "Solicitudes" */
  CreditAnalystApplicationComponent() {
    return this.dlg.open(CreditAnalystApplicationComponent, this.configModal);
  }

  /* Modal desplegado desde "Investigador" > "Solicitudes" */
  InvestigatorComponent() {
    return this.dlg.open(RequestInvestigatorComponent, this.configModal);
  }

  /* Modal desplegado desde "Referencias" > "Solicitudes" */
  ReferencesRequestComponent() {
    return this.dlg.open(RequestReferencesComponent, this.configModal);
  }

  openModalCard(modalName) {

    switch (modalName) {
      case "assignRequest":{
        return this.documentValidatorApplication();
      }
      case 'DocumentValidatorApprovedDocumentationComponent':{
        return this.documentValidatorApprovedDocumentation();
      }
      case 'CreditAnalystApplicationComponent':{
        return this.CreditAnalystApplicationComponent();
      }
      case 'RequestInvestigatorComponent' : {
        return this.InvestigatorComponent();
      }
      case 'RequestReferencesComponent' : {
        return this.ReferencesRequestComponent();
      }
    }
  }


  openGallery() {
    return this.dlg.open(FilePreviewOverlayComponent, this.configModal);
  }
}
