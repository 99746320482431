import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MonthlyPaymentsComponent } from '@shared/components/monthly-payments/monthly-payments.component';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CarListService {
  private stateService = true
  private unsubcribe: Subject<void> = new Subject();
  constructor(private httpSvc: HttpService) { }

  closeServices() {
    //this.stateService = false;
    this.unsubcribe.next();
    this.unsubcribe.complete();
    console.log("unsubscribes")
  }

  getCarsList() {
    let path = "simulator/cars";

    return this.httpSvc.setUrlBase(environment.landingDomain).get(path).toPromise()
  }

  getCarsListFilter(filterField, searchText) {
    let params = {
      filter_by: filterField,
      search: searchText
    }
    let queryString = this.httpSvc.setUrlBase(environment.landingDomain).objToQueryString(params);
    let path = "simulator/cars?" + queryString;

    return this.httpSvc.setUrlBase(environment.landingDomain).get(path).toPromise()
  }

  getAmortizationTable(items: any) {
    let path = "simulator/table";
    return this.httpSvc.setUrlBase(environment.landingDomain).post(path, items).toPromise();
  }

  sendAmortizationTable(params: any) {
    let path = "simulator/email";
    return this.httpSvc.setUrlBase(environment.landingDomain).post(path, params).toPromise();
  }

  downloadAmortizationTable(params: any) {
    let path = "simulator/pdf";
    return this.httpSvc.setUrlBase(environment.landingDomain).post(path, params).toPromise();
  }
}
