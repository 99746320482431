import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'wnbo-confirm-validation',
  templateUrl: './confirm-validation.component.html',
  styleUrls: ['./confirm-validation.component.scss']
})
export class ConfirmValidationComponent implements OnInit {
  public modalType = {
    approve: 1,
    reject: 2
  };
  public documentTypes = {
    id_front: 'Identificación oficial',
    id_back: 'Reverso identicicación oficial',
    last_salary_slips: 'Último comprobante',
    previus_salary_slips: 'Comprobante previo',
    antepenultimate_salary_slips: 'Comprobante anterior',
    address_proof: 'Comprobante de domicilio'
  }

  constructor(
    public dialogRef: MatDialogRef<ConfirmValidationComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

  closeModal(option?) {
    this.dialogRef.close(option);
  }

}
