import { Component, OnInit, Inject } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'wnbo-file-preview-overlay',
  templateUrl: './file-preview-overlay.component.html',
  styleUrls: ['./file-preview-overlay.component.scss']
})
export class FilePreviewOverlayComponent implements OnInit {
  public index = 0;
  public name: string;
  public length: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilePreviewOverlayComponent>
  ) {
    this.index = this.data.index;
    this.length = this.data.images.length;
  }



  ngOnInit() {
    this.getName();
  }

  getName() {
    this.name = 'Imagen ' + (this.index+1) +' de ' + this.length;
  }

  close() {
    this.dialogRef.close();
  }

  next() {
    if (this.index < (this.length - 1)) {
      this.index += 1;
      this.getName();
    }
  }

  prev() {
    if (this.index > 0) {
      this.index -= 1;
      this.getName();

    }
  }



}
