import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthSessionService } from '../auth/auth-session.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public httpOptions: any;
  public httpHeaders: HttpHeaders;
  private urlBase = environment.dominio + environment.basePath;

  constructor(
    private httpClient: HttpClient,
    private authSvc: AuthSessionService
  ) {
    this.httpHeaders = new HttpHeaders();
  }

  loadHeaders() {
    this.httpOptions = {
      headers: this.httpHeaders
    };
    return this.httpOptions;
  }

  loadToken() {
    let tokens = this.authSvc.setNameSession("sessionbo").getAllData();
    if (tokens) {
      return {
        headers: new HttpHeaders({ 'Authorization': 'Bearer ' + tokens.id_token })
      };
    }
    return {};
  }

  setHeader(key, value) {
    this.httpHeaders = this.httpHeaders.set(key, value);
  }

  setUrlBase(path?:string) {
    this.urlBase = path || environment.dominio + environment.basePath;
    return this;
  }

  getUrlBase() {
    return this.urlBase;
  }

  get(pathUrl, headers = {}): Observable<any> {
    let url = this.getUrlBase() + pathUrl;
    return this.httpClient.get(url, headers).pipe(resp => resp);
  }

  post(pathUrl, params, headers = {}): Observable<any> {
    let url = this.getUrlBase() + pathUrl;
    return this.httpClient.post(url, params, headers).pipe(resp => resp);
  }

  put(pathUrl: string, params: any, headers = {}): Observable<any> {
    let url = this.getUrlBase() + pathUrl;
    return this.httpClient.put(url, params, headers).pipe(response => response);
  }

  delete(pathUrl, params, headers = {}): Observable<any> {
    let url = this.getUrlBase() + pathUrl;
    headers['body'] = params;
    return this.httpClient.delete(url, headers).pipe(resp => resp);
  }

  objToQueryString(obj: Object) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }
}
