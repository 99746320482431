import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(number, args): any {
    let phone = String(number).split('');
    if (args) {
      const numberL = number.length;
      return ('('+number[0]+number[1]+') '+number.substring(2,6) + '-' +number.substring(6,(numberL)));
    } else {
      return phone[0]+phone[1]+' '+phone[2]+phone[3]+phone[4]+phone[5]+'-'+phone[6]+phone[7]+phone[8]+phone[9]
    }

  }

}
