import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'wnbo-card-already-assigned-validator',
  templateUrl: './card-already-assigned-validator.component.html',
  styleUrls: ['./card-already-assigned-validator.component.scss']
})
export class CardAlreadyAssignedValidatorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CardAlreadyAssignedValidatorComponent>
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
