import { Directive, ElementRef, HostListener, ViewChild, Renderer, Output, EventEmitter } from '@angular/core';
import { ZipCodeHttpService } from './zip-code-http.service';

@Directive({
  selector: '[wnZipCode]'
})
export class ZipCodeDirective {

  @Output() nameState  = new EventEmitter();
  @Output() nameMunicipality = new EventEmitter();
  @Output() listNeighborhood = new EventEmitter();
  constructor(private _el:ElementRef, private render: Renderer, private zipCodeSvc:ZipCodeHttpService) {   }

  @HostListener('keyup', ['$event']) onKeyUp(e) {
    let limitZipCode = 5;
    this.nameState.emit('');
    this.nameMunicipality.emit('');
    const initalValue:string = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if ( initalValue !== this._el.nativeElement.value || initalValue.length > limitZipCode) {
      event.stopPropagation();
    }
    this.zipCodeSvc.getZipCode(this._el.nativeElement.value).then(success => {
      let data = success['data'];
      if(data.state)
      {
        this.nameState.emit(data.state)
      }
      if(data.municipality)
      {
        this.nameMunicipality.emit(data.municipality)
      }
      if(data.neighborhood)
      {
        let list = Object.assign([], data.neighborhood)
        this.listNeighborhood.emit(list)
      }
    }, error =>{
      console.log(error)
    })

    
  }

  @HostListener('input', ['$event.taget']) onChange(e) {
    console.log(e)
    let limitZipCode = 5;
    this.nameState.emit('');
    this.nameMunicipality.emit('');
    const initalValue:string = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if ( initalValue !== this._el.nativeElement.value || initalValue.length > limitZipCode) {
      event.stopPropagation();
    }
    this.zipCodeSvc.getZipCode(this._el.nativeElement.value).then(success => {
      let data = success['data'];
      if(data.state)
      {
        this.nameState.emit(data.state)
      }
      if(data.municipality)
      {
        this.nameMunicipality.emit(data.municipality)
      }
      if(data.neighborhood)
      {
        let list = Object.assign([], data.neighborhood)
        this.listNeighborhood.emit(list)
      }
    }, error =>{
      console.log(error)
    })

    
  }

}
