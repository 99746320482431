import { Component, OnInit, Input } from '@angular/core';
import { BoardsService } from '@shared/services/http/boards.services';
@Component({
  selector: 'wnbo-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss']
})
export class ColumnComponent implements OnInit {
  @Input() dataColumn:any;
  title:string;
  numCards:number=0;
  iconColumn:string="people";
  cardList:Array<any>=[];
  loaderStatus:boolean=false;
  constructor(private boardSvc:BoardsService) { }

  ngOnInit() {
    this.title = this.dataColumn.name;
    this.loadCardData();
  }

  async loadCardData(){
    this.loaderStatus = true;
    try {
      const resp = await this.boardSvc.getTable(this.dataColumn.table);
      this.numCards = resp.data.length;
      this.cardList = resp.data;
      this.loaderStatus = false;
    } catch (error) {
      this.loaderStatus = false;
      console.log(error);
    }
  }
  
}
