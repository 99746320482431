import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wnbo-inner-step-form',
  templateUrl: './inner-step-form.component.html',
  styleUrls: ['./inner-step-form.component.scss']
})
export class InnerStepFormComponent implements OnInit {
  @Input() formData;

  constructor() { }

  ngOnInit() {
  }

  formClassNameGenerator(stepName) {
    var newClassFormName = stepName.replace(/\s/g, '-');

    newClassFormName = 'form-group-' + newClassFormName;

    return newClassFormName;
  }

  formGroupNameGenerator(stepName) {
    var capitalizeName = stepName.replace(/\w+/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    }).replace(/\s/g, '');

    capitalizeName = 'formGroup' + capitalizeName;

    return capitalizeName;
  }

  formItemClassNameGenerator(itemName) {
    var newClassItemName = itemName.replace(/\s/g, '-');

    newClassItemName = 'field-' + newClassItemName;

    return newClassItemName;
  }

}
