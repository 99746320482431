import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthSessionService } from '@shared/services/auth/auth-session.service';
import { SESSION_BACKOFFICE } from '@shared/data/data';
import { UserService } from '@shared/services/http/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'wnbo-backoffice-navbar',
  templateUrl: './backoffice-navbar.component.html',
  styleUrls: ['./backoffice-navbar.component.scss']
})
export class BackofficeNavbarComponent implements OnInit {

  sideNavOpen = false;
  userName:string;

  @Output() sideBarStat = new EventEmitter<boolean>();

  constructor(private authSvc:AuthSessionService, private userSvc:UserService, private router:Router) { 
  }

  ngOnInit() {
    this.loadInfoAdmin()
  }

  toggleSideNav() {
    this.sideNavOpen = !this.sideNavOpen;
    this.sideBarStat.emit(this.sideNavOpen);
  }

  loadInfoAdmin()
  {
    let email = this.authSvc.setNameSession(SESSION_BACKOFFICE).getKeySession("email");
    this.userSvc.getAdminInfo(email).then(success => {
      if(Object(success.data).hasOwnProperty('name'))
      {
        this.userName = success.data.name
      }else
      {
        this.userName = email;
      }
    }, error => {})
  }

  closeSession()
  {
    this.authSvc.clearAllSession();
    this.router.navigate(['/auth'])
  }

}
