import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'wnbo-authorization-process',
  templateUrl: './authorization-process.component.html',
  styleUrls: ['./authorization-process.component.scss']
})
export class AuthorizationProcessComponent implements OnInit {
  @Input() modalTitle:string = "";

  constructor(public dialogRef:MatDialogRef<AuthorizationProcessComponent>, private router:Router) { }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

  finalizeProcess() {
    this.closeModal();
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 1000);
  }

}
