import { Component, OnInit, Inject } from '@angular/core';
import { CarListService } from '@shared/services/http/car-list.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'wnbo-amortization-table',
  templateUrl: './amortization-table.component.html',
  styleUrls: ['./amortization-table.component.scss']
})
export class AmortizationTableComponent implements OnInit {
  
  public amortizationData: Array<any> = [];
  public headerColumn: Array<any> = [];
  public creditDownPaymentPercentage: number = 0;
  public quotation: any;
  public table: Array<any> = [];
  public today: any;

  constructor(
    private carService: CarListService,
    public dialogRef: MatDialogRef<AmortizationTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.today = new Date().toLocaleDateString();
    this.creditDownPaymentPercentage = (this.data.credit_down_payment * 100) / this.data.credit_car_cost;
    this.carService.getAmortizationTable(this.data).then(
      response => {
        this.quotation = response.data
        this.table = Object.values(this.quotation.credit_table)
        this.headerColumn = Object.values(this.table.shift())
        this.amortizationData = this.table.map(row => Object.values(row))
      }
    );
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public print() {
    if (this.quotation) {
      this.carService.downloadAmortizationTable(this.data).then(
        response => {
          const downloadLink = document.createElement("a");
          const fileName = "tabla_de_amortizacion.pdf";
          downloadLink.href = response.data;
          downloadLink.download = fileName;
          downloadLink.click();
        },
        error => console.error(error)
      );
    }
  }

}
