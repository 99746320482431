import { Component, OnInit, Input } from '@angular/core';
import { BoardsService } from '@shared/services/http/boards.services';

@Component({
  selector: 'wnbo-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {
  columnsList:Array<any>=[];
  @Input() name:string;
  constructor(private boardSvc:BoardsService) { }

  ngOnInit() {
    this.loadConfiguration()
  }

  loadConfiguration()
  {
    this.boardSvc.getBoard(this.name).then(success =>{
      this.columnsList = Object.assign([], success.data.column)
    }, error => {
      console.log('board',error)
    })
  }

  onClick()
  {
    console.log("sobre la card")
  }



}
