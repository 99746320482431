import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'wnbo-warning-delete-user',
  templateUrl: './warning-delete-user.component.html',
  styleUrls: ['./warning-delete-user.component.scss']
})
export class WarningDeleteUserComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<WarningDeleteUserComponent>) { }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

  accept()
  {
    this.dialogRef.close(true);
  }

}
