import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'wnbo-exit-doc-validation-with-save',
  templateUrl: './exit-doc-validation-with-save.component.html',
  styleUrls: ['./exit-doc-validation-with-save.component.scss']
})
export class ExitDocValidationWithSaveComponent implements OnInit {

  constructor(public dialogRef:MatDialogRef<ExitDocValidationWithSaveComponent>) { }

  ngOnInit() {
  }

  closeModal(opt?) {
    this.dialogRef.close(opt);
  }

}
