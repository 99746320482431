import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GetUrlFileService } from '@shared/services/s3/get-url-file.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'wnbo-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @Input() isGallery: boolean;
  @Input() customClass: string;
  @Input() galleryTitle: string; 
  @Input() minHeight: number;
  @Input() editable: boolean = true;
  @Input() userData: any;
  @Input() docType: any;
  @Input() gallerySize: number;
  @Input() smallPhotosNumber: number;
  @Input() photosArr = [];
  @Output() savePhotoEmit = new EventEmitter<boolean>();
  @Output() photosLenhtEmit = new EventEmitter<number>();

  public isErrorSize = false;
  public loaderStatus =false;
  public photosChargedArr = [];

  constructor(
    private fileSvc: GetUrlFileService,
    private modalSvc: ModalService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if(!this.minHeight) { this.minHeight = 110}
    if(this.photosArr.length != 0) {
      if(this.editable) {
        this.savePhotoEmit.emit(true);
        this.photosLenhtEmit.emit(this.photosArr.length);
      }
      if(this.photosArr.length == 1 && !this.isGallery) {
        if(this.photosArr[0].name.includes('pdf')) {
          const newFile = this.sanitizer.bypassSecurityTrustResourceUrl(this.photosArr[0].file.toString());
          this.photosArr[0].file = newFile;
        }
        this.photosChargedArr = this.photosArr;
        this.photosLenhtEmit.emit(this.photosChargedArr.length);
      } else {
      this.photosChargedArr = this.photosArr;
      }

    }
    
  }

  showGallery(index?) {
    this.modalSvc.setParams({images:this.photosChargedArr, index: index? index : 0}).setPanelClass('gallery-modal').openGallery();
  }

  openModalImage(document) {
    const doctoShow = {
      name_document: document.name,
      b64: document.file
    }
    this.modalSvc.setParams(doctoShow).documentImageValidation();
  }

  upload(items) {
    if(!this.isGallery)  {
      if (!this.isValidateSizeImage(items)) { return; }
        let reader = new FileReader();
        this.loaderStatus = true;
        let _this = this;
        reader.onload = () => {
            const image = {
              name:items.target.files[0].name,
              file:reader.result
            }
            _this.savePhoto(image);
        }
        reader.readAsDataURL(items.target.files[0]);
    }else{
      if(this.photosChargedArr.length < this.gallerySize) {
        if (!this.isValidateSizeImage(items)) { return; }
        let reader = new FileReader();
        this.loaderStatus = true;
        let _this = this;
        reader.onload = () => {
            const image = {
              name:items.target.files[0].name,
              file:reader.result
            }
            
            _this.savePhoto(image);
        }
        reader.readAsDataURL(items.target.files[0]);
      }
    } 
    
  }

  public isValidateSizeImage(items: any) {
    this.isErrorSize = items.target.files[0].size > 10485760;
    return !this.isErrorSize
  }

  async savePhoto(image) {
    try {
      const resp = await this.fileSvc.uploadInvImage(image,this.userData,this.docType);
      if(image.name.includes('pdf')) {
        const newFile = this.sanitizer.bypassSecurityTrustResourceUrl(image.file.toString());
        image.file = newFile;
      }
      this.photosChargedArr.unshift(image);
      this.photosLenhtEmit.emit(this.photosChargedArr.length);
      this.savePhotoEmit.emit(true)
      this.loaderStatus = false;
    } catch (error) {
      this.loaderStatus = false;
      this.modalSvc.setPanelClass('error-upload')
          .labelButtonAccept('Continuar')
          .setTextTitle('¡Ha ocurrido un error!')
          .setTextMessage('Lo sentimos, no se ha podido cargar el documento, intenta nuevamente')
          .error();
    }
  }

  async deletePhoto(image, index) {
    try {
      this.loaderStatus = true;
      const body = {
        request_id: this.userData.requestId,
        name_document: image.name
      }
      const resp = await this.fileSvc.deleteInvImage(body);
      this.photosChargedArr.splice(index,1);
      this.photosLenhtEmit.emit(this.photosChargedArr.length);
      this.loaderStatus = false;
    } catch (error) {
      this.loaderStatus = false;
    }
  }

}
