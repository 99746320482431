import { Component, OnInit, Inject, Input } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  @Input() title:string ="";
  @Input() message:string = "";
  @Input() close:string ="";
  @Input() accept:string = "";
  
  constructor( public dialogRef: MatDialogRef<SuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  onNoClick(): void {
      this.dialogRef.close();
  }

  aceptar() {
    this.dialogRef.close(true);
  }

  cerrar(): void {
    this.dialogRef.close(false);
  }

}
