import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wnbo-payment-terms',
  templateUrl: './payment-terms.component.html',
  styleUrls: ['./payment-terms.component.scss']
})
export class PaymentTermsComponent {

  @Input() listMonthlyPayments: any;
  @Output() periodSelect = new EventEmitter();

  public updatePeriod(item: any){
    if (item) {
      this.periodSelect.emit(item.payment)
    }
  }

}
