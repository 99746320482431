import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'wnbo-expired-session-modal',
  templateUrl: './expired-session-modal.component.html',
  styleUrls: ['./expired-session-modal.component.scss']
})
export class ExpiredSessionModalComponent implements OnInit {
  public sessionStatus = {
    inactivity: 0,
    tokenExpired: 1
  };

  constructor(private dialogRef: MatDialogRef<ExpiredSessionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,) { }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
