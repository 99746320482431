import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BoardsService } from '@shared/services/http/boards.services';
import * as moment from 'moment';
@Component({
  selector: 'wnbo-document-validator-approved-documentation',
  templateUrl: './document-validator-approved-documentation.component.html',
  styleUrls: ['./document-validator-approved-documentation.component.scss']
})
export class DocumentValidatorApprovedDocumentationComponent implements OnInit {
  panelOpenState;
  public item: any;
  public labelStatus: string;
  constructor(
    public dialogRef: MatDialogRef<DocumentValidatorApprovedDocumentationComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private boardSvc: BoardsService
  ) { }

  ngOnInit() {
    this.item = this.data;
    this.timeLife();
    console.log(this.data);
    this.getUserInfo();
  }

  flagStatus() {
    //    let status = "active"
    let status = this.item.status;
    let ngClass = "status-2";
    this.labelStatus = "Baja";
    this.item.timeLife = this.timeLife();
    this.item.timeHours = this.timeHours();

    if (this.item.timeHours < 12) {
      ngClass = "status-2";
      this.labelStatus = "Baja";
    } else if (this.item.timeHours > 12 && this.item.timeHours < 24) {
      ngClass = "status-1"
      this.labelStatus = "Media";
    } else {
      ngClass = "status-0"
      this.labelStatus = "Alta";
    }

    return ngClass;
  }

  timeHours()
  {
    let startTime = moment(this.item.created_at)
    let end = moment()
    let duration = moment.duration(end.diff(startTime));
    let hours = duration.asHours();
    return Math.floor(hours);
  }

  timeLife() {
    let startTime = moment(this.item.created_at)
    let end = moment()
    let duration = moment.duration(end.diff(startTime));
    let hours = duration.asHours();
    let minutes = Math.floor((hours % 1) * 60);
    let timeString = String(Math.floor(hours)).padStart(2, '0') + ":" + String(minutes).padStart(2, '0') + ":00"
    return timeString;
  }

  closeModal() {
    this.dialogRef.close();
  }

  async getUserInfo() {
    try {
      const resp = await this.boardSvc.getDataProspect(this.item.email);
      this.item.personalInfo = resp.data;
      console.log(this.item);
    } catch (error) {
      console.log(error);
    }
  }

}
