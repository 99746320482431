import { NgModule } from '@angular/core';
import { CommonModule, formatNumber } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { NgxCurrencyModule } from "ngx-currency";
import { BackofficeSidenavComponent } from './components/backoffice-sidenav/backoffice-sidenav.component';
import { BackofficeNavbarComponent } from './components/backoffice-navbar/backoffice-navbar.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputPassComponent } from './components/input-pass/input-pass.component';
import { SuccessComponent } from './services/modal/success/success.component';
import { ConfirmComponent } from './services/modal/confirm/confirm.component';
import { ErrorComponent } from './services/modal/error/error.component';
import { NotificationComponent } from './services/modal/notification/notification.component';
import { LoaderComponent } from './services/modal/loader/loader.component';
import { PasswordChangeSuccessComponent } from './components/password-change-success/password-change-success.component';
import { InnerStepFormComponent } from './components/inner-step-form/inner-step-form.component';
import { CategoryCarsComponent } from './components/category-cars/category-cars.component';
import { MonthlyPaymentsComponent } from './components/monthly-payments/monthly-payments.component';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { CreditInfoComponent } from './components/credit-info/credit-info.component';
import { PaymentTermsComponent } from './components/payment-terms/payment-terms.component';
import { SimulationResultComponent } from './components/simulation-result/simulation-result.component';
import { AmortizationTableComponent } from './components/amortization-table/amortization-table.component';
import { ShareAmortizationTableComponent } from './components/share-amortization-table/share-amortization-table.component';
import { CreditBureauAuthorizationComponent } from './components/credit-bureau-authorization/credit-bureau-authorization.component';
import { AuthorizationProcessComponent } from './components/authorization-process/authorization-process.component';
import { ProspectDataComponent } from './components/prospect-data/prospect-data.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { WarningDeletePermissionComponent } from './components/warning-delete-permission/warning-delete-permission.component';
import { WarningDeleteUserComponent } from './components/warning-delete-user/warning-delete-user.component';
import { WarningComponent } from './services/modal/warning/warning.component';
import { ErroRoleDeleteComponent } from './services/modal/erro-role-delete/erro-role-delete.component';
import { RouterModule } from '@angular/router';
import { DocumentValidatorApplicationComponent } from './components/document-validator-application/document-validator-application.component';
import { ExitDocValidationWithoutSaveComponent } from './components/exit-doc-validation-without-save/exit-doc-validation-without-save.component';
import { ExitDocValidationWithSaveComponent } from './components/exit-doc-validation-with-save/exit-doc-validation-with-save.component';
import { DocumentValidatorApprovedDocumentationComponent } from './components/document-validator-approved-documentation/document-validator-approved-documentation.component';
import { CreditAnalystApplicationComponent } from './components/credit-analyst-application/credit-analyst-application.component';
import { ColumnComponent } from './components/board/column/column.component';
import { CardComponent } from './components/board/card/card.component';
import { BoardComponent } from './components/board/board/board.component';
import { ZipCodeDirective } from './directives/ZipCode/zip-code.directive';
import { NumbersDirective } from './directives/numbers.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { CardAlreadyAssignedValidatorComponent } from './components/card-already-assigned-validator/card-already-assigned-validator.component';
import { DatePipe } from './pipes/date.pipe';
import { DocumentImageComponent } from './components/document-image/document-image.component';
import { ExpiredSessionModalComponent } from './components/expired-session-modal/expired-session-modal.component';
import { ConfirmValidationComponent } from './components/confirm-validation/confirm-validation.component';
import { ReleaseCreditRequestComponent } from './components/release-credit-request/release-credit-request.component';
import { RequestInvestigatorComponent } from './components/request-investigator/request-investigator.component';
import { ReleaseRequestComponent } from './components/release-request/release-request.component';
import { ExitRequestComponent } from './components/exit-request/exit-request.component';
import { RequestReferencesComponent } from './components/request-references/request-references.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { FilePreviewOverlayComponent } from './components/file-preview-overlay/file-preview-overlay.component';
import { GalleryComponent } from "./components/gallery/gallery.component";
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    BackofficeSidenavComponent,
    BackofficeNavbarComponent,
    InputPassComponent,
    SuccessComponent,
    ConfirmComponent,
    ErrorComponent,
    NotificationComponent,
    LoaderComponent,
    PasswordChangeSuccessComponent,
    InnerStepFormComponent,
    CategoryCarsComponent,
    MonthlyPaymentsComponent,
    SelectFilterComponent,
    CategoryCarsComponent,
    CreditInfoComponent,
    PaymentTermsComponent,
    SimulationResultComponent,
    AmortizationTableComponent,
    ShareAmortizationTableComponent,
    CreditBureauAuthorizationComponent,
    AuthorizationProcessComponent,
    ProspectDataComponent,
    InputFileComponent,
    WarningDeletePermissionComponent,
    WarningDeleteUserComponent,
    WarningComponent,
    ErroRoleDeleteComponent,
    DocumentValidatorApplicationComponent,
    ExitDocValidationWithoutSaveComponent,
    ExitDocValidationWithSaveComponent,
    DocumentValidatorApprovedDocumentationComponent,
    CreditAnalystApplicationComponent,
    ColumnComponent,
    CardComponent,
    BoardComponent,
    ZipCodeDirective,
    NumbersDirective,
    FormatNumberPipe,
    CardAlreadyAssignedValidatorComponent,
    DatePipe,
    DocumentImageComponent,
    ExpiredSessionModalComponent,
    ConfirmValidationComponent,
    ReleaseCreditRequestComponent,
    RequestInvestigatorComponent,
    ReleaseRequestComponent,
    ExitRequestComponent,
    RequestReferencesComponent,
    FilePreviewOverlayComponent,
    GalleryComponent
  ],
  entryComponents:[
    SuccessComponent,
    ConfirmComponent,
    ErrorComponent,
    NotificationComponent,
    LoaderComponent,
    InputPassComponent,
    PasswordChangeSuccessComponent,
    CategoryCarsComponent,
    CreditInfoComponent,
    PaymentTermsComponent,
    SimulationResultComponent,
    AmortizationTableComponent,
    ShareAmortizationTableComponent,
    CreditBureauAuthorizationComponent,
    AuthorizationProcessComponent,
    ProspectDataComponent,
    InputFileComponent,
    WarningDeletePermissionComponent,
    WarningDeleteUserComponent,
    ErroRoleDeleteComponent,
    DocumentValidatorApplicationComponent,
    ExitDocValidationWithoutSaveComponent,
    ExitDocValidationWithSaveComponent,
    DocumentValidatorApprovedDocumentationComponent,
    CreditAnalystApplicationComponent,
    ColumnComponent,
    CardComponent,
    BoardComponent,
    CardAlreadyAssignedValidatorComponent,
    DocumentImageComponent,
    ExpiredSessionModalComponent,
    ConfirmValidationComponent,
    ReleaseCreditRequestComponent,
    RequestInvestigatorComponent,
    ExitRequestComponent,
    ReleaseRequestComponent,
    RequestReferencesComponent,
    FilePreviewOverlayComponent,
    GalleryComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxCurrencyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: true
    }),
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    RouterModule,
    OverlayModule
  ],
  exports: [
    MaterialModule,
    TranslateModule,
    NgxCurrencyModule,
    PdfViewerModule,
    BackofficeSidenavComponent,
    BackofficeNavbarComponent,
    FormsModule,
    ReactiveFormsModule,
    InputPassComponent,
    InnerStepFormComponent,
    CategoryCarsComponent,
    CreditInfoComponent,
    PaymentTermsComponent,
    SimulationResultComponent,
    ProspectDataComponent,
    InputFileComponent,
    WarningDeletePermissionComponent,
    WarningDeleteUserComponent,
    DocumentValidatorApplicationComponent,
    ExitDocValidationWithoutSaveComponent,
    ExitDocValidationWithSaveComponent,
    DocumentValidatorApprovedDocumentationComponent,
    CreditAnalystApplicationComponent,
    ColumnComponent,
    CardComponent,
    BoardComponent,
    ZipCodeDirective,
    NumbersDirective,
    FormatNumberPipe,
    CardAlreadyAssignedValidatorComponent,
    DatePipe,
    DocumentImageComponent,
    ExpiredSessionModalComponent,
    ConfirmValidationComponent,
    ReleaseCreditRequestComponent,
    RequestInvestigatorComponent,
    ExitRequestComponent,
    ReleaseRequestComponent,
    RequestReferencesComponent,
    OverlayModule,
    FilePreviewOverlayComponent,
    GalleryComponent
  ]
})
export class SharedModule { }
platformBrowserDynamic().bootstrapModule(SharedModule);