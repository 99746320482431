import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'wnbo-warning-delete-permission',
  templateUrl: './warning-delete-permission.component.html',
  styleUrls: ['./warning-delete-permission.component.scss']
})
export class WarningDeletePermissionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<WarningDeletePermissionComponent>) { }

  ngOnInit() {
  }

  continue(){
    this.dialogRef.close(true);    
  }

  closeModal() {
    this.dialogRef.close();
  }

}
