import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ExpiredSessionModalComponent } from '@shared/components/expired-session-modal/expired-session-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{

  constructor(private router: Router, private dlg: MatDialog) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

    return next.handle(req).pipe( tap(() => {},
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401 && err.status !== 0) {
         return;
        }
        if(localStorage.getItem('sessionbo')) {
          this.closeExpiredSession();
        }
      }
    }));
  }

  closeExpiredSession() {
    localStorage.clear();
    this.router.navigate(['/auth']);
    this.dlg.open(ExpiredSessionModalComponent, { data: 1, disableClose: true});
  }
}
