import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpSvc: HttpService) { }

  login(user) {
    let path = "backoffice-access/login"
    let params = {
      "email": user.user,
      "password": user.pass
    }
    return this.httpSvc.setUrlBase().post(path, params).toPromise()
  }

  recoveryPassword(email) {
    let path = "backoffice-access/forgoot"
    let params = {
      "email": email,
    }
    return this.httpSvc.setUrlBase().post(path, params).toPromise();
  }

  applyResetPassword(newPassword) {
    let path = "backoffice-access/applyforgoot"
    return this.httpSvc.setUrlBase().post(path, newPassword).toPromise();
  }

  createUser(user) {
    let path = "backoffice-admin"
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().post(path, user, header).toPromise();
  }

  deleteUser(email) {
    let path = "backoffice-admin"
    let header = this.httpSvc.loadToken();
    let params = {
      admin_email: email
    }
    return this.httpSvc.setUrlBase().delete(path, params, header).toPromise();
  }

  updateUser(user) {
    let path = "backoffice-admin"
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().put(path, user, header).toPromise();
  }

  getUserList() {
    let path = "backoffice-admin/list"
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().get(path, header).toPromise();
  }

  validateUser(user) {
    let path = "backoffice-access/validate"
    return this.httpSvc.setUrlBase().post(path, user).toPromise();
  }

  getAdminInfo(email: String) {
    let mail = this.httpSvc.objToQueryString({ admin_email: email });
    let path = "backoffice-admin?" + mail;
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().get(path, header).toPromise();
  }

  resendEmail(email: any) {
    return this.httpSvc.setUrlBase().post('backoffice-access/resend', email).toPromise();
  }

  
  getImageb64(name) {
    let path = "backoffice-document?name_document=" + name;
    // console.log('path', path);
    let header = this.httpSvc.loadToken();
    return this.httpSvc.get(path, header).toPromise();
  }

  getImageb64ForValidation(name) {
    let path = "backoffice-document?name_document=" + name;
    let header = this.httpSvc.loadToken();
    return this.httpSvc.setUrlBase().get(path, header).toPromise();
  }
}
