import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BoardsService } from '@shared/services/http/boards.services';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'wnbo-document-validator-application',
  templateUrl: './document-validator-application.component.html',
  styleUrls: ['./document-validator-application.component.scss']
})
export class DocumentValidatorApplicationComponent implements OnInit {
  panelOpenState;
  item;
  labelStatus:string;
  private statusClass: string;
  public percentage = 0;

  constructor(
    public dialogRef:MatDialogRef<DocumentValidatorApplicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private boardSvc:BoardsService,
    private router:Router
    ) { }

  ngOnInit() {
    this.item = this.data;
    this.getUserInfo();
  }

  closeModal(option?) {
    this.dialogRef.close(option);
  }

  async assign(){
    try {
      const resp = await this.boardSvc.requestAssignToValidator(this.item.card_id);
      console.log(resp);
      //email del prospecto guardada en el servicio
      this.boardSvc.setProspectEmail = this.item.email;
      this.boardSvc.setCardStatus = {label: this.labelStatus, class: this.statusClass};
      this.closeModal();
      this.router.navigate(['validator/document-list/' + this.item.card_id +'/'+this.item.request_id]);
    } catch (error) {
      this.boardSvc.setProspectEmail = '';
      this.closeModal(0);
      console.log(error);
    }
  }

  async getUserInfo() {
    try {
      const resp = await this.boardSvc.getDataProspect(this.item.email);
      this.item.personalInfo = resp.data;
      this.getPercentage(resp.data.documents);
      console.log(this.item);
    } catch (error) {
      console.log(error);
    }
  }

  async getProgressValidation() {
    try {
      const resp = await this.boardSvc.getValidationProgress(this.data.card_id);
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  }

  getPercentage(documents: Array<any>) {
    var docArr = {
      id: [],
      address: [],
      salary: [] 
    };
    documents.filter(doc => (doc.status == 'pending' || doc.status == 'approved' || doc.status == 'rejected')).map( (doc: any) => {
      if(doc.subtype_document){
        docArr.id.push(doc.status);
      }
      if(doc.type_document == "address_proof") {
        docArr.address.push(doc.status)
      }
      if(doc.type_document == "previus_salary_slips") {
        docArr.salary.push(doc.status)
      }
      if(doc.type_document == "antepenultimate_salary_slips") {
        docArr.salary.push(doc.status)
      }
      if(doc.type_document == "last_salary_slips") {
        docArr.salary.push(doc.status)
      }
    });

    if(docArr.id.length == 1) {
      if(docArr.id[0] == 'approved') {
        this.percentage = this.percentage + 33.33;
      }
    } else {
      if(docArr.id[0] == 'approved' && docArr.id[1] == 'approved') {
        this.percentage = this.percentage + 33.33;
      }
    }

    if(docArr.address[0] == 'approved') {
      this.percentage = this.percentage + 33.33;
    }

    if (docArr.salary[0] == 'approved' && docArr.salary[1] == 'approved' && docArr.salary[2] == 'approved') {
      this.percentage = this.percentage + 33.33;
    }

  }

  flagStatus(){
    //    let status = "active"
        let status = this.item.status;
        let ngClass = "status-2";
        this.labelStatus = "Baja";
        this.item.timeLife = this.timeLife();
    
        if(this.item.timeLife < 12)
        {
          ngClass = "status-2";
          this.labelStatus = "Baja";
        }else if(this.item.timeLife > 12 && this.item.timeLife < 24)
        {
          ngClass = "status-1"
          this.labelStatus = "Media";
        }else{
          ngClass = "status-0"
          this.labelStatus = "Alta";
        }
        this.statusClass = ngClass;
        return ngClass;
      }
    
      timeLife(){
        let startTime = moment(this.item.created_at)
        let end = moment()
        let duration = moment.duration(end.diff(startTime));
        let hours = duration.asHours();
        return Math.floor(hours);
      }

}
