import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { CarListService } from '@shared/services/http/car-list.service'
import { SIMULATOR_YEAR, SIMULATOR_BRAND, SIMULATOR_NAME, SIMULATOR_VERSION } from '@shared/data/data';
import { AuthSessionService } from '@shared/services/auth/auth-session.service';

@Component({
  selector: 'wnbo-category-cars',
  templateUrl: './category-cars.component.html',
  styleUrls: ['./category-cars.component.scss']
})
export class CategoryCarsComponent implements OnInit, OnChanges {

  @Output() price = new EventEmitter();
  @Output() values = new EventEmitter();
  @Input() formData: any;

  public cars = [];
  public years = [];
  public brands = []
  public models = []
  public versions = []

  public brand: string;
  public model: string;
  public version: string;
  public year: string ;

  constructor(
    private carListSvc: CarListService,
    private sessionSvc: AuthSessionService
  ) { }

  ngOnInit() {
    this.getCars();
  }

  ngOnChanges() {
    if(this.formData) {
      let fields = this.formData.fields;
      for(let field of fields) {
        let value = field.value;
        if(field.name == "car_model_year" && value && value != "") {
          this.year = value;
        } else if (field.name == "car_make_name" && value && value != "") {
          this.brand = value;
        } else if(field.name == "car_model_name" && value && value != "") {
          this.model = value
        } else if(field.name == "car_version" && value && value != "") {
          this.version = value
        }
      }
    }
  }

  public getCars() {
    this.carListSvc.getCarsList().then(
      response => {
        this.cars = response.data;
        this.years = [...new Set(this.cars.map((car: any) => car.model_years))]
        this.brands = [...new Set(this.cars.map((car: any) => car.make_name))]
        this.models = [...new Set(this.cars.map((car: any) => car.model_name))]
        this.versions = [...new Set(this.cars.map((car: any) => car.version))]
      },
      error => console.error(error)
    )
  }

  public filterOptions(field: string, text: string) {
    this.carListSvc.getCarsListFilter(field, text).then(response => {
      switch (field) {
        case SIMULATOR_YEAR:
          this.setBrandCars(response.data)
          this.year = text;
          break;
        case SIMULATOR_BRAND:
          this.setModelCars(response.data)
          this.brand = text;
          break;
        case SIMULATOR_NAME:
          this.setVersionCars(response.data)
          this.model = text
          break;
        case SIMULATOR_VERSION:
          this.version = text
          this.setValueCarCost(this.getCar());
          break;
      }
    }, error => {
      console.error(error)
    })
  }

  public getCar() {
    let car = this.cars.find(car => car.model_years == this.year &&
      car.make_name == this.brand && car.model_name == this.model && car.version == this.version);
    return car
  }

  public setBrandCars(items: Array<any>) {
    this.brands = [...new Set(items.map((car: any) => car.make_name))]
  }

  public setModelCars(items: Array<any>) {
    this.models = [...new Set(items.map((car: any) => car.model_name))]
  }

  public setVersionCars(items: Array<any>) {
    this.versions = [...new Set(items.map((car: any) => car.version))]
  }

  public setValueCarCost(item: any) {
    this.price.emit(parseFloat(item.price))
    this.setValues()
  }
  
  public setValues() {
    this.values.emit({
      brand: this.brand,
      model: this.model,
      version: this.version,
      year: this.year
    });
  }

  public getKeyCar(key: any) {
    let memory = this.sessionSvc.setNameSession("selectCar").getAllData();
    return memory && memory.hasOwnProperty(key) ? memory[key] : null
  }

}
