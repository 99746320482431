import { Component, OnInit, OnChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { SimulatorService } from '@shared/services/http/simulator.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'wnbo-credit-info',
  templateUrl: './credit-info.component.html',
  styleUrls: ['./credit-info.component.scss']
})
export class CreditInfoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() carCost: number;
  @Input() formData: any;
  @Input() creditPeriodPayment: number = 48;
  @Output() setCreditAmount = new EventEmitter();
  @Output() setCreditDownPayment = new EventEmitter();
  @Output() setListMonthly = new EventEmitter();

  private keyMonthlyPayment = new Subject<string>();
  private keyDownPayment = new Subject<string>();
  private subsMonthlyPayment: Subscription;
  private subsDownPayment: Subscription;
  private oldCarCost: number;
  private isFirstLoad = true;
  
  public creditDownPayment: number = 0;
  public minDownPayment: number;
  public maxDownPayment: number;

  public creditDownPaymentPercent: number = 30;
  public minDownPaymentPercentage: number = 25;
  public maxDownPaymentPercentage: number;

  public periodsPayments: Array<number> = [];
  public monthlyPayments = [];
  public monthlyPayment: number;
  public minMonthlyPayment: number;
  public maxMonthlyPayment: number;

  public creditAmount: number;
  public minCreditAmount: number;
  public maxCreditAmount: number;

  constructor(
    private simulatorService: SimulatorService
  ) {
    this.subsMonthlyPayment = this.keyMonthlyPayment.pipe(
      debounceTime(900),
      distinctUntilChanged())
      .subscribe(() => {
          this.getSimulatorChangeMonthlyPayment();
      });
    this.subsDownPayment = this.keyDownPayment.pipe(
      debounceTime(900),
      distinctUntilChanged())
      .subscribe(() => {
        this.creditDownPaymentPercent = (this.creditDownPayment * 100) / this.carCost;
        this.changeCreditAmount();
      });
  }

  ngOnInit() {
    this.loadDefaults();
  }

  ngOnChanges() {
    if(this.creditPeriodPayment){
      this.changePeriodPayment(this.creditPeriodPayment);
    }
    if (this.carCost && this.oldCarCost != this.carCost) {
      this.changeCreditAmount();
    }
    if(this.isFirstLoad) {
      let fields = this.formData.fields;
      for(let field of fields) {
        let value = field.value;
        if(field.name == 'credit_amount' && value && value != "") {
          this.creditAmount = parseFloat(value);
        } else if (field.name == 'credit_monthly_payment' && value && value != "") {
          this.monthlyPayment = parseFloat(value);
        } else if(field.name == 'credit_down_payment' && value && value != "") {
          this.creditDownPayment = parseFloat(value)
        }
      }
    }
  }

  public loadDefaults() {
    this.simulatorService.getFactoryCredit().then(
      success => {
        let data = success.data
        this.minDownPaymentPercentage = parseInt(data.down_payment.min_percentage);
        this.periodsPayments = data.periods_payment.map(period => parseInt(period));
        this.minCreditAmount = parseInt(data.loan_amount.min);
        this.maxCreditAmount = parseInt(data.loan_amount.max);
        if(this.isFirstLoad) {
          this.creditPeriodPayment = parseInt(data.defaults.period_payment);
          this.creditDownPaymentPercent = parseInt(data.defaults.down_payment);
        }
        this.isFirstLoad = false
      }, error => console.error(error)
    )
  }

  public getSimulationInit() {
    let params: Simulator = {
      credit_amount: this.creditAmount,
      credit_car_cost: this.carCost,
      credit_period_payment: this.creditPeriodPayment,
      credit_down_payment: this.creditDownPayment
    }
    this.simulatorService.getSimulationCredit(params).then(
      success => {
        this.monthlyPayments = success.data.payments;
          for (let index = 0; index < this.monthlyPayments.length; index++) {
            this.monthlyPayments[index].active = false;
            if (index == this.monthlyPayments.length - 1) {
              this.minMonthlyPayment = this.monthlyPayments[index].payment_amount
            } else if (index == 0) {
              this.maxMonthlyPayment = this.monthlyPayments[index].payment_amount
            }
            if (this.creditPeriodPayment == this.monthlyPayments[index].payment) {
              this.monthlyPayment = this.monthlyPayments[index].payment_amount;
              this.monthlyPayments[index].active = true;
              this.simulatorService.setSubjectMonthlyPayment(this.monthlyPayments[index]);
            }
          }
          this.setCreditAmount.emit(this.creditAmount)
          this.setListMonthly.emit(this.monthlyPayments)
      }, error => console.error(error)
    )
  }

  public getSimulatorChangeMonthlyPayment() {
    let params: Simulator = {
      credit_amount: this.creditAmount,
      credit_car_cost: this.carCost,
      credit_period_payment: this.creditPeriodPayment,
      credit_monthly_payment: this.monthlyPayment
    }
    this.simulatorService.getSimulationCredit(params).then(
      success => {
        this.monthlyPayments = success.data.payments;
        this.creditDownPayment = success.data.credit_down_payment;
        for (let index = 0; index < this.monthlyPayments.length; index++) {
          this.monthlyPayments[index].active = false;
          if (this.creditPeriodPayment == this.monthlyPayments[index].payment) {
            this.monthlyPayment = this.monthlyPayments[index].payment_amount;
            this.monthlyPayments[index].active = true;
            this.simulatorService.setSubjectMonthlyPayment(this.monthlyPayments[index]);
          }
        }
        this.setListMonthly.emit(this.monthlyPayments);
        this.setCreditAmount.emit(this.creditAmount)
      },
      error => console.error(error)
    )
  }

  public changeCreditAmount() {
    this.oldCarCost = this.carCost;
    this.calculateLimitsDownPayment();
    this.calculateDownPayment();
    this.calculateCreditAmount();
    this.getSimulationInit();
  }

  public calculateLimitsDownPayment() {
    this.minDownPayment = this.carCost * (this.minDownPaymentPercentage / 100);
    if((this.carCost - this.minDownPayment) > this.maxCreditAmount) {
      this.minDownPayment = this.carCost - this.maxCreditAmount
    }
    this.maxDownPayment = this.carCost - this.minCreditAmount;
    this.maxDownPaymentPercentage = (this.carCost - this.minCreditAmount) * 100 / this.carCost
  }

  public calculateDownPayment() {
    if(this.creditDownPayment == 0) {
      this.creditDownPayment = this.carCost * (this.creditDownPaymentPercent / 100);
    }
    if(this.creditDownPayment > this.maxDownPayment) {
      this.creditDownPayment = this.maxDownPayment
    } else if(this.creditDownPayment < this.minDownPayment) {
      this.creditDownPayment = this.minDownPayment
    } else {
      this.creditDownPayment = this.carCost * (this.creditDownPaymentPercent / 100)
    }
    this.creditDownPaymentPercent = (this.creditDownPayment * 100) / this.carCost;
    this.setCreditDownPayment.emit(this.creditDownPayment)
  }

  public calculateCreditAmount() {
    this.creditAmount = this.carCost - this.creditDownPayment;
  }

  public changePeriodPayment(item: any) {
    this.creditPeriodPayment = item.value ? item.value : item;
    this.monthlyPayments.map(monthlyPayment => monthlyPayment.active = monthlyPayment.payment == this.creditPeriodPayment)
    let monthlyPaymentFind = this.monthlyPayments.find(monthlyPayment => monthlyPayment.active);
    if (monthlyPaymentFind) {
      this.monthlyPayment = monthlyPaymentFind.payment_amount
      this.simulatorService.setSubjectMonthlyPayment(monthlyPaymentFind);
    }
  }

  ngOnDestroy(){
    this.subsDownPayment.unsubscribe();
    this.subsMonthlyPayment.unsubscribe();
  }


}
