import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthSessionService {
  private nameSession = "sessionUser";
  constructor(private router: Router) { }

  setNameSession(name: string) {
    this.nameSession = name;
    return this;
  }

  setDataSession(user) {
    localStorage.setItem(this.nameSession, JSON.stringify(user))
    return this;
  }

  getAllData() {
    return JSON.parse(localStorage.getItem(this.nameSession))
  }

  getKeySession(key) {
    let data = this.getAllData();
    if (data != null) {
      if (data.hasOwnProperty(key)) {
        return data[key];
      }
    }
    return null;
  }

  setKeySession(key, value) {
    let data = this.getAllData();
    if (!data) {
      data = [];
    }
    data[key] = value;
    this.setDataSession(data)
    return this;
  }

  clearSession() {
    localStorage.removeItem(this.nameSession);
    return this;
  }

  clearAllSession() {
    localStorage.clear();
    return this;
  }

  isLogged() {
    let token = this.getKeySession('id_token');
    let id = this.getKeySession('id');
    if (token == id) {
      return false;
    }
    return true;
  }

  closeSession() {
    this.clearSession()
    this.router.navigate(['/auth'])
  }

}
