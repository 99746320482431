import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'wnbo-erro-role-delete',
  templateUrl: './erro-role-delete.component.html',
  styleUrls: ['./erro-role-delete.component.scss']
})
export class ErroRoleDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ErroRoleDeleteComponent>, private router:Router) { }

  ngOnInit() {
  }

  continue(){
    this.dialogRef.close();
    this.router.navigate(['/admin/users'])
  }

  closeModal() {
    this.dialogRef.close();
  }


}
